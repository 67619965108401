import React from "react";
import Layout from "../../components/Layout/Layout";
import { graphql } from "gatsby";
import styled from "styled-components";
import { mediaQuery } from "../../components/_breakpoints";
import { colorsGlobal } from "../../components/_global";

export default ({
  data: {
    markdownRemark: {
      frontmatter: { textList, description },
    },
  },
}) => (
  <Layout>
    <Wrapper>
      <ContactQuestion>{description}</ContactQuestion>
      {textList.map(({ text }) => {
        const pattern = /\[mail\=\=(.*?)\]/;
        const splitedText = text.split(pattern);

        if (splitedText.length > 0) {
          return (
            <SingleContactContent>
              {splitedText.map((el, index) =>
                el.indexOf("@") > 0 ? (
                  <MailAnchor href={`mailto:${el}`}>{el}</MailAnchor>
                ) : (
                  el
                )
              )}
            </SingleContactContent>
          );
        }
        return <SingleContactContent>{text}</SingleContactContent>;
      })}
    </Wrapper>
  </Layout>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 0 20px;
  margin-top: 100px;
  padding: 0 10px;
  border-radius: 5px;
  z-index: 50;
  justify-content: flex-start;
  background: rgba(255, 255, 255, 0.5);
  ${mediaQuery.xl(`
    max-width: 1280px;
  `)}
  ${mediaQuery.sm(`
    background: transparent;
    margin: 0 auto;
    margin-top: 100px;
    border-radius: 0;
    margin-left: 100px;
    justify-content: flex-start;
    align-items: flex-start;
  `)}
`;

const SingleContactContent = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin-top: 5px;
  &:before{
    content: "👉";
    margin-right: -5px;
    left: -15px;
    position: relative;
  }
  ${mediaQuery.sm(`
    font-size: 22px;
    text-align: left;
    line-height: 25px;
  `)}

  ${mediaQuery.xl(`
    font-size: 22px;
    line-height: 28px;`)}
`;

const ContactQuestion = styled.h3`
  text-align: center;
  font-size: 18px;
  padding: 0 0 20px;
  margin-left: 0;
  ${mediaQuery.sm(`
    font-size: 20px;
  `)}
`;

const MailAnchor = styled.a`
  cursor: pointer;
  color: ${colorsGlobal.primary};
`;

export const contactPageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        description
        textList {
          text
        }
      }
    }
  }
`;
